import React from 'react';
import { Link, graphql } from 'gatsby';
import InViewMonitor from 'react-inview-monitor';
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardImgOverlay,
  CardBody,
  CardTitle,
  CardText,
  Button,
} from 'reactstrap';
import Layout from '../components/layout';
import SEO from '../components/SEO';
import PageHeader from '../components/Sections/PageHeader';
import Sidebar from '../components/Sections/Sidebar';
import QuickForm from '../components/Sections/QuickForm';

const sectionTitle = 'Lavori svolti';

const IndexPage = ({ data: { allDatoCmsProject: projects }, pageContext }) => (
  <Layout>
    <SEO title={sectionTitle} />
    <PageHeader title={sectionTitle} />
    <div id="page" className="section">
      <Container>
        <Row>
          <Col sm={{ size: 8, order: 2 }}>
            {projects.edges.map((project) => {
              const categories = project.node.category || [];
              return (
                <InViewMonitor classNameNotInView="animate-box" classNameInView="animated fadeInUp" key={project.node.id}>
                  <Card className="bg-dark text-white mb-2 mb-sm-5">
                    <CardImg width="100%" src={project.node.gallery.length > 0 ? project.node.gallery[0].sizes.src : 'https://placeimg.com/800/300/nature'} alt={project.node.name} />
                    <CardImgOverlay className="d-flex align-items-end">
                      <CardBody>
                        <CardText tag="h6">{categories.map(c => c.name).join(', ')}</CardText>
                        <CardTitle tag="h4">{project.node.name}</CardTitle>
                        <Link to={`/progetti/${project.node.slug}`} className="stretched-link" />
                      </CardBody>
                    </CardImgOverlay>
                  </Card>
                </InViewMonitor>
              );
            })}
            {pageContext.currentPage < pageContext.numPages
              ? (
                <div className="mt-5 text-right">
                  <Link to={`/progetti/${pageContext.currentPage + 1}`}>
                    <Button outline color="primary" type="button" className="btn-icon">
                    Meno recenti
                      <i className="icon-arrow-right" />
                    </Button>
                  </Link>
                </div>
              ) : ''
            }
          </Col>
          <Col sm={{ size: 4, order: 1 }}>
            <Sidebar />
          </Col>
        </Row>
      </Container>
    </div>
    <QuickForm />
  </Layout>
);

export default IndexPage;

export const pageQuery = graphql`
  query ProjectsQuery($skip: Int!, $limit: Int!) {
    allDatoCmsProject(limit: $limit, skip: $skip, sort: { fields: [position], order: ASC }) {
      edges {
        node {
          id
          slug
          name
          description
          gallery {
            sizes(imgixParams: {fm: "jpg", crop: "focalpoint", fit: "crop", h: "300", w: "800"}, maxWidth: 800, maxHeight: 300) {
              src
            }
          }
          category {
            name
            slug
          }
        }
      }
    }
  }
`;
